import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="container termsPage">
        <h1> Web Privacy Notice </h1>
        <p>Last Updated June 1, 2022</p>
        <p>This website or application (this “Site”) is owned and operated by Canton Animal Hopsital PLLC or one or more of its direct or indirect subsidiaries and affiliates (collectively, the “Company,” “we,” or “us”). The Company knows that you care how information about you is used and shared. This Privacy Notice explains what we may do with information that we collect from or about you when you use the Site or certain other affiliated websites or applications that link to the Site or post this Privacy Notice. </p>
        <p>1. Acceptance of Privacy Notice. Please read this Privacy Notice carefully. By browsing or using this Site, you accept and agree to the terms of this Privacy Notice. If you do not want to agree to the terms of this Privacy Notice, please do not access or use this Site.</p>
        <p>2. This Site Does Not Provide Medical Advice. The contents of this Site are available to you for informational purposes only, including text, graphics, images, and other information provided by us or licensed from third parties on this Site. Although the Site provides educational information about clinical research and health care issues, it does not provide medical diagnoses or recommendations regarding an individual’s medical treatment. Please do not make decisions about your health care in reliance on the information provided through this Site. Instead, always seek the advice of a physician or qualified health care provider with any questions you may have regarding a medical condition or treatment options. In an emergency, call 911 or your local emergency assistance. Certain uses or disclosures of personal information may be governed by other privacy notices and policies. At times, your health care providers may collect personal information about you that constitutes Protected Health Information (“PHI”) pursuant to the Health Insurance Portability and Accountability Act (“HIPAA”). If you have questions relating to use of your PHI in connection with health care services received by you from a health care provider, you should contact your health care provider.</p>
        <p>3. Personal Information We Collect. In this Privacy Notice, “personal information” means information that may identify you as an individual. Personal information does not include information that has been deidentified in such a way that your identity cannot reasonably be determined. We may collect personal information directly from you such as when you interact with our Site, create an account, complete a form, or respond to communication such as e-mail. When interacting with our Site, we may ask you for your name, e-mail address, mailing address, phone number, credit card information or other information, depending on the circumstances. You may, however, visit our Site without providing such information.</p>
        <p>We also receive and store other types of personal information whenever you interact with our Site. For example, we may use web beacons and other technologies to collect information about your use of our Site, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. Like many websites, we use “cookies” and other tracking technologies such as pixel tags, to enhance your experience and gather information about visitors and visits to our Site. Please refer to the “Cookies” section below for information about cookies and other tracking technologies and how we use them.</p>
        <p>4. How We Use Personal Information. The personal information we may collect from and about you helps us personalize and continually improve the user experience on our Site. We may use the personal information we collect from you in some or all of the following ways:</p>
        <p>to personalize and improve your experience on our Site and to allow us to deliver the type of content and product offerings in which you are most interested;</p>
        <p>to allow us to better service you in responding to your requests and providing you with information, products or services that you request;</p>
        <p>to identify potential clinical research subjects;</p>
        <p>to process your transactions;</p>
        <p>to verify your identity;</p>
        <p>to administer a promotion, survey or other Site feature;</p>
        <p>to troubleshoot problems with the Site or our services;</p>
        <p>to provide you with email alerts and notices concerning our services, events, or news. You may opt out of receiving our emails by using the “unsubscribe” link contained in any email that we send;</p>
        <p>to improve our website and present its contents to you;</p>
        <p>to protect the safety and security of subjects of clinical research trials, our affiliated medical practices, our workforce, guests, property and assets, including monitoring activities in facilities, devices, networks, communications and resources;</p>
        <p>for testing, research, analysis and product development related to our Site;</p>
        <p>as necessary or appropriate to protect against fraudulent, illegal or unethical activity;</p>
        <p>to respond to law enforcement requests and as required by applicable law, court order, or governmental regulations; and</p>
        <p>to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</p>
        <p>5. How We Share Personal Information. By using this Site, you agree that we may share your personal information as follows:</p>
        <p>with our affiliated entities, subsidiaries, management, employees, contractors, and advisors;</p>
        <p>with vendors that we engage to provide services on our behalf, such as to deliver products or services to you, website hosting, credit card payment processing, order processing, data analytics providers, and external consultants; and</p>
        <p>in connection with a third-party’s evaluation and/or consummation of a corporate transaction with us, such as a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control of our Company.</p>
        <p>We also may disclose your personal information: (i) as permitted by law; (ii) if we determine that the disclosure of specific information is necessary to comply with the request of law enforcement or regulatory agency or other legal processes; (iii) to protect the legitimate rights, privacy, property, interests or safety of our Company or our affiliated entities, business partners, employees or the general public; (iv) to pursue available remedies or limit damages; (v) to enforce our Terms of Use; and (vi) to respond to an emergency.</p>
        <p>6. Cookies. We and our service providers use various tracking technologies, including cookies, to collect information about you when you interact with our websites or applications. Cookies are small files that a website or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. Cookies help us in many ways to make your use of our website more enjoyable and meaningful, such as understanding usage patterns and improving the functionality of the website. For instance, we use cookies to help us understand your preferences based on previous or current website activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about website traffic and website interaction so that we can offer better website experiences and tools in the future. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. If you turn cookies off, you won’t have access to many features that make our website more efficient and some of our services will not function properly.</p>
        <p>7. Analytics Services Provided by Others. We may allow others to provide analytics services on our behalf. For example, we may use analytics partners to help us analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests and other websites and better understand your online activity.</p>
        <p>8. Links to Other Websites and Applications. We may include links to other websites or applications, such as social media plug-ins. If you visit the link, you may be subject to another party’s terms and conditions and privacy policies, which may differ from this Privacy Notice. A link to a third-party website or service does not mean that we endorse that third party or the quality or accuracy of the information presented on its website or service. Nonetheless, we seek to protect the integrity of our website and welcome any feedback about these linked sites (including if a specific link does not work).</p>
        <p>9. Safeguarding your Personal Information. We take reasonable precautions to help protect the personal information that we collect and store from websites and applications. However, no system or online transmission of data is completely secure and we cannot guarantee the security of personal information transmitted online. We encourage you to take reasonable precautions to safeguard your personal information.</p>
        <p>10. Retention. We will store the personal information you provide for as long as we believe is necessary or appropriate to carry out the purposes for which we collected it, or to comply with applicable laws, contracts, or other rules or regulations.</p>
        <p>11. Users Outside the United States. This Site is intended for users located in the United States, and this Privacy Notice describes how we use and disclose your personal information pursuant to United States law. We make no representation that this Site is appropriate or available for use in locations outside of the United States. The privacy laws of the United States and other countries may be different from those in your country, and you understand that by using this Site, your personal information will be processed in the United States or in the countries where our vendors are located.</p>
        <p>12. Use of this Site by Minors. This Site is intended only for adults and we do not knowingly collect personally identifiable information from minors. If you believe this Site might have any information from or about a child, please contact us using the “Contact” information below.</p>
        <p>13. California Residents. The California Consumer Privacy Act of 2018 provides California residents with the right to receive additional information about how certain personal information is collected, used and disclosed, to request deletion of personal information in certain cases, and other rights. If you are a resident of California and wish to receive such information, please contact us using the “Contact” information below.</p>
        <p>14. Changes to this Privacy Notice. We reserve the right to change this Privacy Notice at any time. As business needs change, we will review our practices regarding the collection and use of personal information and amend this Privacy Notice as appropriate. Any such change is effective automatically upon posting on this Site and that effective date is reflected at the top of the page. Your continued use of the Site after such effective date constitutes your acceptance of our amended Privacy Notice.</p>
        <p>15. Contact. We welcome your questions, comments, and concerns about this Privacy Notice, how we collect and use personal information, or your privacy choices and rights. Please send us any questions or feedback to:</p>
        <p>CANTON ANIMAL HOSPITAL PLLC <br />
        Attn: General Counsel <br />
        43439 Michigan Ave <br />
        Canton, MI 48188 <br />
        info@cantonvets.com <br />
        Please make sure that all inquiries are clearly marked “Attn: General Counsel.” <br />
        © 2022. CANTON ANIMAL HOSPITAL PLLC. All Rights Reserved.
        </p>
 

      </div>
    </Layout>
  )
}

export default PrivacyPolicy